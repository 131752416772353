.ant-typography {
  font-weight: 400;

  &.ant-typography-primary {
    color: @primary-color;
  }
}

a.ant-typography-expand {
  color: @primary-color;
}

h1.ant-typography {
  @media (max-width: @osx) {
    font-size: 44px;
  }

  @media (max-width: @xl) {
    font-size: 40px;
  }

  @media (max-width: @lg) {
    font-size: 36px;
  }

  @media (max-width: @sm) {
    font-size: 30px;
  }
}

h2.ant-typography {
  @media (max-width: @lg) {
    font-size: 24px;
  }
}

// h3.ant-typography {
//   @media (max-width: @sm) {
//     font-size: 22px;
//   }
// }

h4.ant-typography {
  font-weight: 600;

  @media (max-width: @md) {
    font-size: 18px;
  }
}

h5.ant-typography {
  font-weight: 400;
}

.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.required-label-title {
  &::before {
    position: relative;
    top: -5px;
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 16px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
}
