.page-event-editor-cover.ant-upload.ant-upload-drag {
  overflow: hidden;
  min-height: 230px;

  &.page-event-editor-cover-small {
    min-height: 190px;
  }

  .ant-upload-drag-container {
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
      object-position: center;
    }
  }
}
