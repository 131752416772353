.alpha-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
}

.alpha-auth-card {
  
}