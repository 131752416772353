.ant-avatar {
  background-color: @primary-color;
  // transform: rotate(45deg);

  // > img {
  //   transform: rotate(-45deg);
  // }

  // > span {
  //   transform: rotate(-45deg) scale(1) translateX(-50%) !important;
  // }

  // &.ant-avatar-square {
  //   border-radius: 35%;
  // }
}

.ant-avatar-group {
  display: flex;

  .ant-avatar {
    transition: 0.3s;

    &:hover {
      filter: brightness(120%);
    }
  }
}
