.ant-picker {
  width: 100%;
  padding: 12px 15px;
  border-width: 1px;
  border-radius: @border-radius-sm;
}

.ant-picker-panel-container {
  border-radius: @border-radius-sm;
}

.ant-picker-ok {
  .ant-btn {
    border-radius: @border-radius-sm;
  }
}

.ant-picker-header,
.ant-picker-datetime-panel .ant-picker-time-panel,
.ant-picker-time-panel-column:not(:first-child),
.ant-picker-panel-container .ant-picker-panel {
  border-width: 1px;
}

.ant-picker-active-bar {
  display: none;
}

.ant-picker-cell .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 7px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border-radius: 7px 0 0 7px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  border-radius: 0 7px 7px 0;
}

.ant-picker-cell .ant-picker-cell-inner {
  min-width: 28px;
  height: 28px;
  line-height: 28px;
}

.ant-picker-cell::before,
.ant-picker-cell::after {
  height: 28px !important;
}
