.ant-input {
  // border: none;
  border-width: 1px;
  border-radius: @border-radius-sm;
}

.ant-input-bordered {
  border: 1px solid #c4c4c4;
}

.ant-input-group {
  display: flex;

  .ant-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ant-input-affix-wrapper {
  border-width: 1px;
  border-radius: @border-radius-sm;

  .ant-input {
    border-radius: 0;
  }
}
