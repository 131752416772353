.ant-dropdown-menu,
.ant-select-dropdown {
  border-radius: @border-radius-sm;
  overflow: hidden;
}

.ant-dropdown-menu-item {
  padding: 8px 10px;
  font-weight: 600;
}
