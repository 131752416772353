.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-width: 1px;
  border-radius: @border-radius-sm;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 7px;
}
