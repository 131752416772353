.ant-radio-group {
  padding: 4px;
  border-radius: @border-radius-sm;
  background-color: #ffffff;
}

.ant-radio-button-wrapper {
  border-left-width: 2px;
  border-radius: @border-radius-sm !important;
  background-color: transparent;
  border-color: transparent !important;
  font-weight: 600;
  line-height: 40px;
  color: #a0a0a0;

  &:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
  }

  &:not(:first-child) {
    &::before {
      display: none;
    }
  }

  &.ant-radio-button-wrapper-checked {
    color: #ffffff;
    background-color: @primary-color;

    &:hover {
      color: #ffffff;
      background: #a14df0;
    }
  }

  + .ant-radio-button-wrapper {
    margin-left: 3px;
  }
}

.ant-radio-group-small {
  .ant-radio-button-wrapper {
    height: 34px;
    border-radius: @border-radius-sm;
    line-height: 30px;
    font-size: @font-size-sm;
  }
}
