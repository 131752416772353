.page-profile-tab-nav {
  width: 100%;
  display: flex;
  
  @media (max-width: @md) {
    flex-wrap: wrap;
  }

  .ant-radio-button-wrapper {
    width: calc(50% - 3px);
    text-align: center;
  }
}