.ant-upload {
  &.ant-upload-drag {
    .ant-upload {
      padding: 0;
    }
  }
}

.ant-upload-drag-container {
  display: flex !important;
  align-items: center;
  border-radius: @border-radius-base;
  overflow: hidden;
}

.ant-upload-remove-button,
.ant-upload-remove-item-button {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}

.ant-upload-remove-item-button {
  top: auto;
  bottom: 15px;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  border-width: 1px;
  border-radius: @border-radius-sm;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 34px;
}
