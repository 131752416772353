.ant-btn {
  // &::after {
  //   display: none;
  // }

  // &.ant-btn-icon-only {
  //   .anticon svg {
  //     transform: scale(1);
  //   }
  // }

  &:not(.ant-btn-icon-only) .anticon:not(.anticon-loading) svg {
    transform: scale(1.3);

    @media (max-width: @sm) {
      transform: scale(1.2);
    }
  }

  // &:not(.ant-btn-primary) {
  //   --antd-wave-shadow-color: @btn-default-bg;
  // }

  &.ant-btn-sm {
    &.ant-btn-icon-only {
      @media (max-width: @sm) {
        width: 34px;
        height: 34px;
      }
    }
  }

  &.default-blur {
    --antd-wave-shadow-color: rgba(#000000, 0.5);

    color: #ffffff;
    border: 0;
    background-color: rgba(#000000, 0.4);
    backdrop-filter: blur(2px);

    &:hover {
      color: #ffffff;
      background-color: rgba(#000000, 0.5);
    }
  }

  &.default-dark-blur {
    --antd-wave-shadow-color: rgba(#000000, 0.8);

    color: #ffffff;
    border: 0;
    background-color: rgba(#000000, 0.7);
    backdrop-filter: blur(2px);

    &:hover {
      color: #ffffff;
      background-color: rgba(#000000, 0.8);
    }
  }

  &.default-white-blur {
    --antd-wave-shadow-color: rgba(#ffffff, 0.4);

    color: #ffffff;
    border: 0;
    background-color: rgba(#ffffff, 0.3);
    backdrop-filter: blur(2px);

    &:hover {
      color: #ffffff;
      background-color: rgba(#ffffff, 0.4);
    }

    &:disabled {
      color: darken(#ffffff, 20%);
      background-color: rgba(darken(#ffffff, 15%), 0.3);
    }
  }

  &.default-gray-blur {
    --antd-wave-shadow-color: rgba(#000000, 0.3);

    color: #000000;
    border: 0;
    background-color: rgba(#000000, 0.05);
    backdrop-filter: blur(2px);

    &:hover {
      color: #000000;
      background-color: rgba(#000000, 0.1);
    }
  }
}

.ant-btn-primary {
  &.ant-btn-dark:not(.ant-btn-background-ghost) {
    --antd-wave-shadow-color: @text-color;

    border: 0;
    background-color: @text-color;

    &:hover {
      background-color: darken(@text-color, 5%);
    }
  }

  &.ant-btn-info-gradient:not(.ant-btn-background-ghost) {
    --antd-wave-shadow-color: #e1b002;

    border: 0;
    background-image: linear-gradient(94deg, #e1b002 0%, #e22dd0 100%);

    &:hover {
      filter: brightness(110%);
    }
  }

  // &.ant-btn-background-ghost {
  //   &.ant-btn-info-gradient {
  //     --antd-wave-shadow-color: #e1b002;
  //   }
  // }
}

.ant-btn-dangerous {
  &.ant-btn-link:hover {
    background-color: rgba(@error-color, 0.05);
  }
}

// .ant-btn-background-ghost {
//   border-width: 2px;
// }
