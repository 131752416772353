.ant-table-thead > tr > th {
  border-bottom: 0;
  font-weight: 600;
  white-space: nowrap;
}

.ant-table-tbody > tr > td {
  border-top: 1px solid #f0f0f0;
  border-bottom: 0;
}

// .ant-table-summary {
// overflow: hidden;
// border-bottom-left-radius: @border-radius-base;
// }
