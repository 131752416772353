.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  border: 2px solid transparent;
  background-color: #ffffff;
  box-sizing: content-box;
  transform: scale(0.7);

  &.swiper-pagination-bullet-active {
    border-color: #ffffff;
    background-color: transparent;
    transform: scale(1);
  }
}
