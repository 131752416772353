.profile-editor-media-cover.ant-upload.ant-upload-drag {
  height: 400px;
  overflow: hidden;

  @media (max-width: @xl) {
    height: 360px;
  }

  @media (max-width: @lg) {
    height: 320px;
  }

  .ant-upload-drag-container {
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
      object-position: center;
    }
  }
}

.profile-editor-media-avatar.ant-upload.ant-upload-drag {
  height: 280px;
  overflow: hidden;

  @media (max-width: @xl) {
    // max-width: 240px;
    height: 240px;
  }

  @media (max-width: @lg) {
    // max-width: 220px;
    height: 220px;
  }

  .ant-upload-drag-container {
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      object-fit: cover;
      object-position: center;
    }
  }
}
