.ant-pagination {
  text-align: center;
}

.ant-pagination-prev,
.ant-pagination-next {
  display: none;
}

.ant-pagination-item {
  border: 0;
  border-radius: @border-radius-sm;
  line-height: 34px;
  font-weight: 600;

  &.ant-pagination-item-active {
    a {
      color: #ffffff;
    }
  }
}
