.page-loading.ant-spin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 140px);
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}
