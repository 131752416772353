.ant-modal-content {
  box-shadow: 0px 8px 30px @text-color-secondary;
}

.ant-modal-close {
  top: 13px;
}

.ant-modal-close-x {
  font-size: 16px;
}

.ant-modal-title {
  font-weight: @typography-title-font-weight;
}
