.ant-tooltip-inner {
  border-radius: @border-radius-sm;
  font-weight: 600;

  .ant-typography {
    &:not(.ant-typography-danger) {
      color: inherit;
    }
  }
}
