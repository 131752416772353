.leaderboard-table {
  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    display: none;
  }

  .ant-table {
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;

    background-image: linear-gradient(
      142.76deg,
      #9816ad 13.89%,
      #4a00e0 85.84%
    );
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 16px 32px;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: transparent;
  }

  .ant-table-thead > tr > th {
    color: inherit;
    background-color: transparent;
  }

  .ant-table-tbody > tr > td {
    border-color: rgba(#ffffff, 0.5);
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-typography {
    color: #ffffff;
    font-weight: inherit;
  }
}

.leaderboard-table-position {
  position: relative;
  display: inline-block;

  img {
    min-width: 40px;
  }

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -45%);
  }
}

.leaderboard-table-user {
  &:hover {
    filter: brightness(110%);
  }
}
